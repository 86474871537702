<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物接收</h3>
    <div class="detail">
      <h4 class="title">生产动物基本情况</h4>
      <div class="desc">
        <span>动物品系：{{ activeInfo.strain }}</span>
        <span>清洁等级：{{
            activeInfo.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级'
          }}</span>
      </div>
      <el-table
          border
          :data="activeInfo.aniOrderDetailList"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          max-height="400px">
        <el-table-column label="基因类型" v-if="activeInfo.isGene==1">
          <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
            <template slot-scope="scope">
              {{ scope.row.typeList[ind].type }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="性别"
            show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.gender == 0 ? '♀' : '♂' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="count"
            label="数量" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="weight"
            label="体重(g)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="weekDays"
            label="周龄" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <el-form ref="addForm" size="small" :model="addExper" class="addForm" label-suffix=":"
               label-width="86px">
        <el-form-item label="外包装" prop="oouter">
          <el-input v-model="addExper.outer" :readonly="readonly" placeholder="请输入外包装信息"></el-input>
        </el-form-item>
        <h3 class="tit">供货单位情况</h3>
        <template v-if="activeInfo.aniSupply">
          <div class="desc">
            <p>
              <span>单位名称：{{ activeInfo.aniSupply.supplier ? activeInfo.aniSupply.supplier : '无' }}</span>
              <span>许可证：{{ activeInfo.aniSupply.prodCert ? activeInfo.aniSupply.prodCert : '无' }}</span>
              <span>合格证号：{{ activeInfo.aniSupply.certNo ? activeInfo.aniSupply.certNo : '无' }}</span>
            </p>
            <p>
              <span>运输工具：{{ activeInfo.aniSupply.transport ? activeInfo.aniSupply.transport : '无' }}</span>
              <span>单位负责人：{{ activeInfo.aniSupply.supplyPrincipal ? activeInfo.aniSupply.supplyPrincipal : '无' }}</span>
              <span>联系电话：{{ activeInfo.aniSupply.supplyPhone ? activeInfo.aniSupply.supplyPhone : '无' }}</span>
            </p>
          </div>
        </template>
        <h3 class="tit">课题动物验收结果</h3>
        <el-form-item label="不合格动物"></el-form-item>
        <el-table
            :data="addExper.aniOrderDetailList"
            border
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            max-height="500">
          <el-table-column label="基因类型" v-if="activeInfo.isGene==1">
            <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
              <template slot-scope="scope">
                {{ scope.row.typeList[ind].type }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column width="80"
                           label="性别">
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column
              prop="belowRecWeight" width="100"
              label="体重(g)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              prop="weekDays"
              label="周龄" width="80">
          </el-table-column>
          <el-table-column label="数量">
            <template slot-scope="scope">
              <el-input size="small" type="number" :min="0" :max="scope.row.count"
                        placeholder="数量" :readonly="readonly"
                        v-model="scope.row.belowRecNum"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="处理方法">
            <template slot-scope="scope">
              <el-input size="small" placeholder="处理方法" :readonly="readonly"
                        v-model="scope.row.belowRecHandle"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <div class="fromSave">
          <template v-if="receptState!=1">
            <el-button type="primary" size="small" @click="submitBtn('addForm',1)">确认接收</el-button>
            <el-button plain type="primary" size="small" @click="submitBtn('addForm',0)">保存
            </el-button>
          </template>
          <el-button plain type="primary" size="small" @click="exportInfo()">导出预览</el-button>
        </div>
      </el-form>
    </div>
  </div>

</template>

<script>
export default {
  name: "taskReceive",
  props: ['id', 'status'],
  data() {
    return {
      activeInfo: {},
      typeList: [],
      addExper: {
        outer: "",
        aniOrderDetailList: []
      },
      receptState: null,
      disabled: false,
      readonly: false
    }
  },
  mounted() {
    if (this.status > 8) {
      //获取提交过的
      this.getReceive()
    } else {
      // 初始化
      this.getType()
    }
    // this.getType()
  },
  methods: {
    getType() {
      let that = this;
      that.$get("/subject/order/to/" + this.id).then(res => {
        if (res.status == 200) {
          that.activeInfo = res.data
          // 表格内的基因类型
          res.data.aniOrderDetailList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
            this.addExper.aniOrderDetailList.push({
              subId: obj.subId,
              id: obj.id,
              typeList: obj.typeList,
              gender: obj.gender,
              belowRecWeight: obj.weight,
              weekDays: obj.weekDays,
              belowRecNum: '',
              count: obj.count,
              belowRecHandle: '',
            })
          })
          this.getTypeList()
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    getReceive() {
      this.$get("/subject/receive/" + this.id).then(res => {
        if (res.status == 200) {
          this.receptState = res.data.aniOrder.aniRecept.state
          if (this.receptState == 1) {
            this.readonly = true
            this.disabled = true
          }
          this.activeInfo = res.data.aniOrder
          this.addExper.outer = res.data.aniOrder.aniRecept.oouter

          // 表格内的基因类型
          res.data.aniOrder.aniOrderDetailList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
            this.addExper.aniOrderDetailList.push({
              subId: obj.subId,
              id: obj.id,
              typeList: obj.typeList,
              gender: obj.gender,
              belowRecWeight: obj.weight,
              weekDays: obj.weekDays,
              belowRecNum: obj.belowRecNum,
              count: obj.realNum,
              belowRecHandle: obj.belowRecHandle,
            })
          })
          this.getTypeList()
        }

      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    getTypeList() {
      // 赋值typeList
      let arr = this.activeInfo.geneKeys.split(',')
      arr.forEach(item => {
        this.typeList.push({
          title: item
        })
      })
    },
    submitBtn(task, state) {
      this.$refs[task].validate((valid) => {
        if (valid) {
          this.addExper.subId = this.id
          this.addExper.state = state
          this.$putJson('/subject/order', this.addExper).then(res => {
            this.$message.success("接收成功")
            setTimeout(this.$router.go(-1), 1200)
          }).catch(err => {
            this.$message.error("接收失败")
          })
        } else {
          return false;
        }
      })
    },
    exportInfo() {
      this.$emit('changeStatus', false, 0)
    },
  },

}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.record-table {
  margin-bottom: 16px;
}
</style>
